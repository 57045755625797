import React, { useState, useCallback } from 'react'
import { Video } from './partials/Video'
import { ProductsPanel } from './partials/ProductsPanel'
import products from 'constants/products'
import style from './OyperPlayer.module.css'
import watermark from './images/oyper_watermark.png'

const OyperPlayer = () => {
  const [itemsIndex, setItemsIndex] = useState(0);
  const onTimeChange = useCallback((time) => {
    const index = products.findIndex((p, index) => p.time > time);
    if (itemsIndex !== index) setItemsIndex(index)
  }, [itemsIndex, setItemsIndex]);

  return (
    <div className={style['oyper-player']}>
      <div className={style['oyper-player__video']}>
        <Video
          onTimeUpdate={(e) => onTimeChange(e.target.currentTime)}
        />
      </div>
      <div className={style['oyper-player__products-panel']}>
        <ProductsPanel
          items={products[itemsIndex] && products[itemsIndex].items}
          nextItems={products[itemsIndex+1] && products[itemsIndex+1].items}
        />
      </div>
      <div className={style['oyper-player__watermark']}>
        <a href="https://oyper.com/" target="_blank" rel="noopener noreferrer">
          <img src={watermark} alt="oyper" />
        </a>
      </div>
    </div>
  )
};

export default OyperPlayer