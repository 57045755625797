import React, {useEffect, useRef} from 'react'
import Plyr from 'plyr'
import './Video.scss'
import posterMobile from './images/poster_mobile.png'
import posterDesktop from './images/poster_desktop.png'

const IS_MOBILE = process.env.REACT_APP_MOBILE_MODE === 'enabled';
const VIDEO_URL = IS_MOBILE
    ? 'https://storage.cloud.google.com/oyper-stream-api/charity/redcross_m.mp4'
    : 'https://charity-player.redcross.by/media/redcross.mp4';

const RATIO = IS_MOBILE ? '10:10' : '16:9';
const PREVIEW = IS_MOBILE ? posterMobile : posterDesktop;

const controls = [
    'play-large',
    'play',
    'progress',
    'current-time',
    'mute',
    'volume',
    'captions',
    // 'fullscreen'
];

const Video = ({onTimeUpdate}) => {
    const videoRef = useRef();
    useEffect(() => {
        try {
            const video = videoRef.current;
            video.setAttribute('webkit-playsinline', true);
            video.autoplay = false;
            const player = new Plyr(video, {
                controls,
                fullscreen: {enabled: false},
                clickToPlay: false,
                muted: false,
                autoplay: false,
                ratio: RATIO
            });
            player.on('loadeddata', () => {
                // player.volume = 0
                player.currentTime = 0;
                video.muted = false;
            });

      video.parentElement && video.parentElement.addEventListener('click', () => {
        if (video.paused) {
          video.play()
        } else {
          video.pause()
        }
      });

      let f = () => {
        try {
          if (!video) return;
          if (video.paused) {
            video.play()
          }
        } catch (e) {
          setTimeout(f, 750)
        }
      };
      setTimeout(f, 100)
    } catch (e) {
      // do nothing
    }
  }, [videoRef]);

    return (
        <video
            ref={videoRef}
            onTimeUpdate={onTimeUpdate}
            poster={PREVIEW}
            controls
            autoPlay
            playsInline
            loop
            muted
        >
            <source src={VIDEO_URL} type="video/mp4"/>
        </video>
    )
};

export default Video
