export default [
                 {
                   "time": 19,
                   "items": [
                       {
                         "url": "",
                         "title": "Перчатки",
                         "previewUrl": "./images/stop-covid.png",
                         "price": "12"
                       }
                   ]
                 },
                 {
                   "time": 25,
                   "items": [
                     {
                       "url": "",
                       "title": "Маски",
                       "previewUrl": "./images/mask-screen.png",
                       "price": "9"
                     },
                     {
                       "url": "",
                       "title": "Респираторы",
                       "previewUrl": "./images/charity_maska.jpg",
                       "price": "9"
                     }
                   ]
                 },
                 {
                   "time": 35,
                   "items": [
                     {
                       "url": "",
                       "title": "Перчатки",
                       "previewUrl": "./images/perchatki-screen.png",
                       "price": "9"
                     },
                     {
                       "url": "",
                       "title": "Перчатки",
                       "previewUrl": "./images/perchatki.jpg",
                       "price": "9"
                     }
                   ]
                 },
                 {
                   "time": 45,
                   "items": [
                     {
                       "url": "",
                       "title": "Антисептик",
                       "previewUrl": "./images/charity_antiseptic.png",
                       "price": "11"
                     }
                   ]
                 },
                 {
                   "time": 55,
                   "items": [
                     {
                       "url": "",
                       "title": "Антисептик",
                       "previewUrl": "./images/antiseptic-screen.png",
                       "price": "11"
                     }
                   ]
                 },
                 {
                   "time": 65,
                   "items": [
                     {
                       "url": "",
                       "title": "Градусники",
                       "previewUrl": "./images/gradusnik.png",
                       "price": "18"
                     }
                   ]
                 },
                 {
                   "time": 75,
                   "items": [
                     {
                       "url": "",
                       "title": "Перчатки",
                       "previewUrl": "./images/stop-covid.png",
                       "price": "12"
                     }
                   ]
                 },
                 {
                   "time": 85,
                   "items": [
                     {
                       "url": "",
                       "title": "Костюмы",
                       "previewUrl": "./images/suit.png",
                       "price": "18"
                     }
                   ]
                 },
                 {
                   "time": 95,
                   "items": [
                     {
                       "url": "",
                       "title": "Респираторы",
                       "previewUrl": "./images/charity_maska.jpg",
                       "price": "9"
                     }
                   ]
                 },
                 {
                   "time": 105,
                   "items": [
                     {
                       "url": "",
                       "title": "Маски",
                       "previewUrl": "./images/mask-screen.png",
                       "price": "9"
                     }
                   ]
                 },
                 {
                   "time": 115,
                   "items": [
                     {
                       "url": "",
                       "title": "Перчатки",
                       "previewUrl": "./images/perchatki-screen.png",
                       "price": "9"
                     },
                     {
                       "url": "",
                       "title": "Перчатки",
                       "previewUrl": "./images/perchatki.jpg",
                       "price": "9"
                     }
                   ]
                 },
                 {
                   "time": 125,
                   "items": [
                     {
                       "url": "",
                       "title": "Антисептик",
                       "previewUrl": "./images/charity_antiseptic.png",
                       "price": "11"
                     }
                   ]
                 },
                 {
                   "time": 135,
                   "items": [
                     {
                       "url": "",
                       "title": "Антисептик",
                       "previewUrl": "./images/antiseptic-screen.png",
                       "price": "11"
                     }
                   ]
                 },
                 {
                   "time": 145,
                   "items": [
                     {
                       "url": "",
                       "title": "Градусники",
                       "previewUrl": "./images/gradusnik.png",
                       "price": "18"
                     }
                   ]
                 },
                 {
                   "time": 160,
                   "items": [
                     {
                       "url": "",
                       "title": "Перчатки",
                       "previewUrl": "./images/stop-covid.png",
                       "price": "12"
                     }
                   ]
                 },
                 {
                   "time": 170,
                   "items": [
                     {
                       "url": "",
                       "title": "Костюмы",
                       "previewUrl": "./images/suit.png",
                       "price": "18"
                     }
                   ]
                 }
               ]