import React from 'react'
import style from './Product.module.css'
import sha1 from 'sha1';

const Product = ({item}) => {
    const {url, title, previewUrl, price} = item;
    const wsb_seed = randomInteger().toString();
    const wsb_storeid = "902300837";
    const wsb_test = "0";
    const wsb_currency_id = "BYN";
    const wsb_total = price.toString();
    const wsb_order_num = "OyperComp-" + wsb_total + "-" + randomInteger().toString();
    const wsb_secretKey = "_asDlk3_fdgT";
    const wsb_signature = sha1(wsb_seed
        .concat(wsb_storeid)
        .concat(wsb_order_num)
        .concat(wsb_test)
        .concat(wsb_currency_id)
        .concat(wsb_total)
        .concat(wsb_secretKey)
    );

    function randomInteger() {
        return Math.floor((Math.random() * 1000000000) + 1);
    }

    function sumbitAyncForm() {
        let form = document.getElementById("rc-form");
        if (form) {
            form.submit();
        }
    }

    function submitForms(event) {
        event.preventDefault();
        setTimeout(sumbitAyncForm, 0);
        let form = document.getElementById("webpay-form");
        if (form) {
            form.submit();
        }
    }

    return (
        <div>
            <form id="webpay-form" action="https://payment.webpay.by/" method="post" target="_blank">
                <input type="hidden" name="*scart"/>
                <input type="hidden" name="wsb_version" value="2"/>
                <input type="hidden" name="wsb_storeid" value={wsb_storeid}/>
                <input type="hidden" name="wsb_order_num" value={wsb_order_num}/>
                <input type="hidden" name="wsb_test" value={wsb_test}/>
                <input type="hidden" name="wsb_currency_id" value="BYN"/>
                <input type="hidden" name="wsb_seed" value={wsb_seed}/>
                <input type="hidden" name="wsb_return_url" value="https://oyper.com/#completed"/>
                <input type="hidden" name="wsb_notify_url"
                       value="https://redcross-prod.secure.force.com/services/apexrest/onetimepayment"/>
                <input type="hidden" name="wsb_invoice_item_name[0]" value={title}/>
                <input type="hidden" name="wsb_invoice_item_quantity[0]" value="1"/>
                <input type="hidden" name="wsb_invoice_item_price[0]" value={price}/>
                <input type="hidden" name="wsb_total" value={price}/>
                <input type="hidden" name="wsb_order_tag" value="OyperCharityProject"/>
                <input type="hidden" name="wsb_signature" value={wsb_signature}/>
            </form>
            <form id="rc-form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                  method="post">
                <input type="hidden" name="oid" value="00D6F000002UgvH"/>
                <input type="hidden" name="lead_source" value="oyper.com"/>
                <input type="hidden" name="retURL" value="https://charity-player.redcross.by/"/>
                <input type="hidden" name="first_name" value="Uladzislau"/>
                <input type="hidden" name="last_name" value="Kaminski"/>
                <input type="hidden" name="email" value="contacts@oyper.com"/>
                <input type="hidden" name="phone" value="+375336388780"/>
                <input type="hidden" name="00N6F00000XYiRb" value=""/>
                <input type="hidden" name="00N6F00000XYiRX" value="Minsk"/>
                <input type="hidden" name="00N6F00000XYiRa" value={wsb_order_num}/>
                <input type="hidden" name="00N6F00000XYiRZ" value="oyper"/>
            </form>


            <a target="_top" className={style['product']}>
                <div className={style['product__content']}>
                    <div className={style['product__image-container']}>
                        <div className={style['product__image']}>
                            <input type="image" src={previewUrl} alt={title}/>
                        </div>
                    </div>
                    <div className={style['product__details']}>
                        <div className={style['product__title']}>{title}</div>
                        <div className={style['product__price-n-btn']}>
                            <div className={style['product__price']}>{price} BYN</div>
                            <button className={style['product__buy_gift']}
                                    onClick={submitForms}>Подарить
                            </button>
                            <button className={style['product__buy_price']}
                                    onClick={submitForms}>{`${price} BYN`}</button>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
};

export default Product
