import React from 'react'
import { Product } from './partials/Product'
import style from './ProductsPanel.module.css'

const ProductsPanel = ({ items, nextItems }) => (
  <div className={style['products-panel']}>
    <div className={style['products-panel__items']}>
      {items && items.map(item => (
        <div key={item.url} className={style['products-panel__item']}>
          <Product item={item} />
        </div>
      ))}
    </div>
    <div className={style['products-panel__next-items']}>
      {nextItems && nextItems.map(item => <Product key={item.url} item={item} />)}
    </div>
  </div>
);

export default ProductsPanel
